import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-hot-toast'
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";

import { MerkleTree } from "merkletreejs";
import keccak256 from "keccak256";
import { ethers } from "ethers";

import tokens from "../allowlist.json";


export const useNFT = () => {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    "CONTRACT_ADDRESS": "0x8Fa6a34aa6A246f3E7F60d43F3596A5e61beD879",
    "SCAN_LINK": "https://etherscan.io/address/0x8Fa6a34aa6A246f3E7F60d43F3596A5e61beD879",
    "NETWORK": {
      "NAME": "Ethereum",
      "SYMBOL": "ETH",
      "ID": 1
    },
    "NFT_NAME": "Ethereal Apes",
    "SYMBOL": "Ethereal Apes",
    "MAX_SUPPLY": 3333,
    "WEI_COST": 80000000000000000,
    "DISPLAY_COST": 0.08,
    "GAS_LIMIT": 100000,
    "MARKETPLACE": "Opensea",
    "MARKETPLACE_LINK": "https://opensea.io/collection/ethereal-apes-official",
    "SHOW_BACKGROUND": true,
    "WHITELIST_ENABLED": false
  });
  // useState({
  //   CONTRACT_ADDRESS: "",
  //   SCAN_LINK: "",
  //   NETWORK: {
  //     NAME: "",
  //     SYMBOL: "",
  //     ID: 0,
  //   },
  //   NFT_NAME: "",
  //   SYMBOL: "",
  //   MAX_SUPPLY: 1,
  //   WEI_COST: 0,
  //   DISPLAY_COST: 0,
  //   GAS_LIMIT: 0,
  //   MARKETPLACE: "",
  //   MARKETPLACE_LINK: "",
  //   SHOW_BACKGROUND: false,
  //   WHITELIST_ENABLED: true,
  // });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    let WHITELIST_ENABLED = CONFIG.WHITELIST_ENABLED;
    console.log(`WHITELIST_ENABLED: `, WHITELIST_ENABLED);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    const toastId = toast.loading(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);

    if (!WHITELIST_ENABLED) {
      blockchain.smartContract.methods
        .publicMint(mintAmount)
        .send({
          gasLimit: String(totalGasLimit),
          to: CONFIG.CONTRACT_ADDRESS,
          from: blockchain.account,
          value: totalCostWei,
        })
        .once("error", (err) => {
          console.log(err);
          setFeedback("Sorry, something went wrong.");
          toast.error("Sorry, something went wrong.", { id: toastId });
          setClaimingNft(false);
        })
        .then((receipt) => {
          console.log(receipt);
          setFeedback(`Your Ethereal Ape has been minted! Congrats!`);
          toast.success(`Your Ethereal Ape has been minted! Congrats!`, { id: toastId });
          setClaimingNft(false);
          dispatch(fetchData(blockchain.account));
        });
    } else {
      console.log(`Mint amount: ${mintAmount}`);
      console.log(`Blockchain Account: ${blockchain.account}`);
      let address = ethers.utils.getAddress(blockchain.account);
      let proof = getMerkleProof(address);
      console.log(`Merkle Proof: ${proof}`);

      let allowance = tokens[address];
      if (allowance == undefined) {
        setFeedback("Address not on Access List.");
        toast.error("Address not on Access List.", { id: toastId });
        return;
      }

      console.log(`allowance: ${allowance}`);
      blockchain.smartContract.methods
        .whitelistMint(mintAmount, allowance, proof)
        .send({
          gasLimit: String(totalGasLimit),
          to: CONFIG.CONTRACT_ADDRESS,
          from: blockchain.account,
          value: totalCostWei,
        })
        .once("error", (err) => {
          console.log(err);
          setFeedback("Sorry, something went wrong.");
          toast.error("Sorry, something went wrong.", { id: toastId });
          setClaimingNft(false);
        })
        .then((receipt) => {
          console.log(receipt);
          setFeedback(`Your Ethereal Ape has been minted! Congrats!`);
          toast.success(`Your Ethereal Ape has been minted! Congrats!`, { id: toastId });
          setClaimingNft(false);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 5) {
      newMintAmount = 5;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  const whitelistLeaves = Object.entries(tokens).map((token) =>
    hashWhitelistToken(...token)
  );
  const merkleTree = new MerkleTree(whitelistLeaves, keccak256, {
    sortPairs: true,
  });

  function hashWhitelistToken(mintingAddress, allowance) {
    return Buffer.from(
      ethers.utils
        .solidityKeccak256(["address", "string"], [mintingAddress, allowance])
        .slice(2),
      "hex"
    );
  }

  function getMerkleProof(address) {
    let allowance = tokens[address];
    console.log(`allowance: `, allowance);

    if (allowance != null) {
      let mintLeaf = hashWhitelistToken(address, allowance);
      let proof = merkleTree.getHexProof(mintLeaf);

      return proof;
    }
  }

  const connectWallet = () => dispatch(connect())

  const maxSupply = CONFIG.MAX_SUPPLY;
  const supply = Number(data.totalSupply);
  const claimedSupply = supply;

    return { 
      data, supply, maxSupply, claimedSupply,
      connectWallet, claimNFTs, mintAmount, decrementMintAmount, incrementMintAmount,
      account: blockchain.account
    }
};
