import { toast } from 'react-hot-toast';
import { useNFT } from "../../hooks/useNFT";

export default function ConnectButton() {
    const { account, connectWallet } = useNFT();
    const showAlert = () => toast.success(`Your wallet is already connected.`);

    return (
        <div>
            <button 
                onClick={account? showAlert: connectWallet}
                className="inline-block text-center bg-gray-700 border border-transparent shadow-md rounded-md md:mr-2 px-6 py-3 sm:mr-2 text-lg font-medium text-white hover:bg-gray-900"
            >
                {account ? `Wallet: ${account.substr(36, 40)}`: 'Connect Wallet' }
            </button>
        </div>
    )
}
