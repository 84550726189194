import ConnectButton from "../organisms/ConnectButton";

export default function Header() {
    return (
        <header className="">
            <nav className="max-w-10xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
                <div className="w-full py-6 flex items-center justify-between lg:border-none">
                    <div></div>
                    <div className="flex items-center">
                        <ConnectButton />
                    </div>
                </div>
            </nav>
        </header>
    )
}