import { SparklesIcon } from '@heroicons/react/outline'
import { BookOpenIcon,  MoonIcon, EyeIcon, LightBulbIcon, ViewGridIcon, TerminalIcon } from '@heroicons/react/outline'

export default function Body() {
  return (
    <>
        <div className="relative bg-white pt-16 pb-32 overflow-hidden">
      <div className="relative">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gray-600">
                  <BookOpenIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-600">
                  The Story
                </h2>
                <p className="mt-4 text-lg text-gray-500 whitespace-normal">
                Ethereal Apes are a 3,333 piece derivative NFT collection on the Ethereum blockchain. <br/>
                Ethereal Apes was inspired by a desire to see the NFT community raise the bar for derivative projects both artistically and technologically.<br/>

                Simply put, we wish to set the standard for quality in derivative projects.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="px-4 md:pl-4 lg:-mr-48 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src="https://ethereal-apes.s3.us-west-2.amazonaws.com/flow.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gray-600">
                  <SparklesIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-600">
                730+ Hand Crafted Assets at 4k UHD Resolution
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                730+ hand drawn assets by our wonderful artist @Aryaenne. <br/>
                All at 4000x4000 at 400 DPI. <br/>
                Print to your hearts content or show off on large high resolution displays with ease.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
            <div className="px-4 lg:-ml-48 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                src="https://ethereal-apes.s3.us-west-2.amazonaws.com/diamond.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      
{/* ROOT FUCKING AROUND */}

      <div className="relative">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gray-600">
                  <ViewGridIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-600">
                Different Fur Types, Unique Fur Fills
                </h2>
                <p className="mt-4 text-lg text-gray-500 whitespace-normal">
                Over a month spent on quality control on professional grade displays to ensure the highest fidelity. <br/>
                More than 2TB worth of samples, over 180,000 images, generated and curated with love<br/>
                </p>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="px-4 lg:-mr-48 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src="https://ethereal-apes.s3.us-west-2.amazonaws.com/sayan-visor.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gray-600">
                  <EyeIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-600">
                Insanely Intricate Backgrounds
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                Most profile pictures have simple backgrounds. <br/>
                Usually, you get a single color, and if youre lucky, some basic artwork.<br/>
                Our backgrounds are composed of 3 separate layers, the Canvas, Background Type, and Background Fill with 5, 6, and 13 combinations each allowing for up to 390 different possible high quality backgrounds
                </p>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
            <div className="px-4 lg:-ml-48 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                src="https://ethereal-apes.s3.us-west-2.amazonaws.com/orb.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      
{/* ROOT FUCKING AROUND 2 */}

      <div className="relative">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gray-600">
                  <TerminalIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-600">
                A Custom Generator
                </h2>
                <p className="mt-4 text-lg text-gray-500 whitespace-normal">
                Early on in our project we realized that simply overlaying layers on top of one another was not going to allow us to achieve the artistic goals we set out for.<br/>
                Because of our goals, not just any open source generator would do. We built a custom generator that allows for complex rulesets, compositions, exclusions, refined rarity calculations, meta traits, and more.<br/>
                </p>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="px-4 lg:-mr-48 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src="https://ethereal-apes.s3.us-west-2.amazonaws.com/zombie-yakuza.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gray-600">
                  <LightBulbIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-600">
                Meta Traits
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                We wanted to build the most premium experience for collectors of our NFTs.<br/>
                Meta traits signal specific combinations that make filters simpler and rarity based aesthetics easier than ever.<br/>
                </p>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
            <div className="px-4 lg:-ml-48 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                src="https://ethereal-apes.s3.us-west-2.amazonaws.com/poseidon.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <br/><br/><br/><br/><br/>

      <div className="relative">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gray-600">
                  <MoonIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-600">
                Gas Efficient Smart Contracts
                </h2>
                <p className="mt-4 text-lg text-gray-500 whitespace-normal">
                Our contract allows for a gas efficient mint with up to 80% savings over your average ERC-721 mint.<br/>
                Oh, and to save everyone even more precious ETH, no approvals are necessary when selling on Opensea.<br/>
                </p>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="px-4 lg:-mr-48 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src="https://ethereal-apes.s3.us-west-2.amazonaws.com/abstract-bow-abyssal.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>




    </div>
    {/* <More /> */}
    </>
  )
}