import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Toaster } from 'react-hot-toast'
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

import { MerkleTree } from "merkletreejs";
import keccak256 from "keccak256";
import { ethers } from "ethers";

import Banner from './components/organisms/Banner';
import Body from "./components/molecules/Body";
import Footer from "./components/layouts/Footer";

import tokens from "./allowlist.json";
import MintCTA from "./components/organisms/MintCTA";
import Team from "./components/organisms/Team";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 7.5px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 120px;
  cursor: pointer;
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 7.5px;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 14px;
  color: var(--primary-text);
  width: 30px;
  height: 25x;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 280px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 6px solid var(--secondary);
  background-color: var(--accent);
  border-radius: 5%;
  width: 300px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;





function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    "CONTRACT_ADDRESS": "0x8Fa6a34aa6A246f3E7F60d43F3596A5e61beD879",
    "SCAN_LINK": "https://rinkeby.etherscan.io/token/0x2dc467e6f00ba934caf9d2dc64a8e9a49a521343",
    "NETWORK": {
      "NAME": "Ethereum",
      "SYMBOL": "ETH",
      "ID": 1
    },
    "NFT_NAME": "Ethereal Apes",
    "SYMBOL": "Ethereal Apes",
    "MAX_SUPPLY": 3333,
    "WEI_COST": 80000000000000000,
    "DISPLAY_COST": 0.08,
    "GAS_LIMIT": 100000,
    "MARKETPLACE": "Opensea",
    "MARKETPLACE_LINK": "https://opensea.io/collection/ethereal-apes-official",
    "SHOW_BACKGROUND": true,
    "WHITELIST_ENABLED": false
  });
  // useState({
  //   CONTRACT_ADDRESS: "",
  //   SCAN_LINK: "",
  //   NETWORK: {
  //     NAME: "",
  //     SYMBOL: "",
  //     ID: 0,
  //   },
  //   NFT_NAME: "",
  //   SYMBOL: "",
  //   MAX_SUPPLY: 1,
  //   WEI_COST: 0,
  //   DISPLAY_COST: 0,
  //   GAS_LIMIT: 0,
  //   MARKETPLACE: "",
  //   MARKETPLACE_LINK: "",
  //   SHOW_BACKGROUND: false,
  //   WHITELIST_ENABLED: true,
  // });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    let WHITELIST_ENABLED = CONFIG.WHITELIST_ENABLED;
    console.log(`WHITELIST_ENABLED: `, WHITELIST_ENABLED)
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);

    if (!WHITELIST_ENABLED) {
      blockchain.smartContract.methods
      .publicMint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `Your Ethereal Ape has been minted! Congrats!`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
    } else {
      console.log(`Mint amount: ${mintAmount}`)
      console.log(`Blockchain Account: ${blockchain.account}`)
      let address = ethers.utils.getAddress(blockchain.account)
      let proof = getMerkleProof(address)
      console.log(`Merkle Proof: ${proof}`)

      let allowance = tokens[address]
      if (allowance == undefined) {
        setFeedback("Address not on Access List.")
        return
      }

      console.log(`allowance: ${allowance}`)
      blockchain.smartContract.methods
      .whitelistMint(mintAmount, allowance, proof)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `Your Ethereal Ape has been minted! Congrats!`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 2) {
      newMintAmount = 2;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

const whitelistLeaves = Object.entries(tokens).map(token => hashWhitelistToken(...token))
const merkleTree = new MerkleTree(whitelistLeaves, keccak256, { sortPairs: true });

function hashWhitelistToken(mintingAddress, allowance) {
    return Buffer.from(ethers.utils.solidityKeccak256(
        ['address', 'string'],
        [mintingAddress, allowance]).slice(2), 'hex')
}


function getMerkleProof(address) {  
  let allowance = tokens[address]
  console.log(`allowance: `, allowance)

  if (allowance != null) {
    let mintLeaf = hashWhitelistToken(address, allowance)
    let proof = merkleTree.getHexProof(mintLeaf)

    return proof
  }
}

  return (
    <s.Screen>
      <Toaster position="bottom-right"  />
      <Banner />
      <Body />
      {/* <Team /> */}
      <MintCTA />
      <Footer />
    </s.Screen>
  );
}

export default App;
