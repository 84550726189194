import NFT from "./NFT"


export default function MintCTA() {
    return (
      <div id="mint" className="bg-white">
        <div className="pt-32 overflow-hidden sm:pt-14">
          <div className="bg-gray-800 shadow-inner inset-4"  style={{ backgroundImage: 'url(/config/images/Background-lower.png)', backgroundSize: 'cover', backgroundPosition: 'center'}}>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="md:relative pt-48 pb-16 sm:pb-24 flex justify-center">
                <div>
                  <h2 id="sale-heading" className="text-4xl font-extrabold tracking-tight text-white md:text-5xl absolute left-0 top-4 ">
                    <br />
                    Public Mint Live Now!
                  </h2>
                  <div className="mt-6 text-base absolute left-0 top-28 ">
                    {/* <NFT/> */}
                  </div>
                </div>
  
                <div className="absolute -top-32 left-1/2 transform -translate-x-1/2 sm:top-6 sm:translate-x-0 hidden">
                  <div className="ml-24 flex space-x-6 min-w-max sm:ml-3 lg:space-x-8">
                    <div className="flex space-x-6 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                      <div className="flex-shrink-0">
                        <img
                          className="h-64 w-64 rounded-lg object-cover md:h-72 md:w-72"
                          src="https://etherealapes.art/static/c0b5b488592f4c4c6808d8ca7799d4eb/89f4f/Apollo-1024.jpg"
                          alt=""
                        />
                      </div>
  
                      <div className="mt-6 flex-shrink-0 sm:mt-0">
                        <img
                          className="h-64 w-64 rounded-lg object-cover md:h-72 md:w-72"
                          src="https://etherealapes.art/static/5bd2eeabf797f05845b8406121061805/16310/Orb.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="flex space-x-6 sm:-mt-20 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                      <div className="flex-shrink-0">
                        <img
                          className="h-64 w-64 rounded-lg object-cover md:h-72 md:w-72"
                          src="https://etherealapes.art/static/c26f31411e107e84263685b3a687852c/16310/sayan-visor.jpg"
                          alt=""
                        />
                      </div>
  
                      <div className="mt-6 flex-shrink-0 sm:mt-0">
                        <img
                          className="h-64 w-64 rounded-lg object-cover md:h-72 md:w-72"
                          src="https://etherealapes.art/static/489f86c2dc7db73bcf75f51d55654718/1abfa/Collector-Sea-Storm.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="flex space-x-6 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                      <div className="flex-shrink-0">
                        <img
                          className="h-64 w-64 rounded-lg object-cover md:h-72 md:w-72"
                          src="https://etherealapes.art/static/cdf5937c0e861dcdf3d080adf77af907/1abfa/zombie-yakuza.jpg"
                          alt=""
                        />
                      </div>
  
                      <div className="mt-6 flex-shrink-0 sm:mt-0">
                        <img
                          className="h-64 w-64 rounded-lg object-cover md:h-72 md:w-72"
                          src="https://etherealapes.art/static/7c1fffb6d3b3cd6ede77a148023901a6/16310/flow.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  