import { StyledLogo } from "../../App"
import Header from "../layouts/Header"
// import { IonIcons } from '@heroicons/react';
import NFT from "./NFT"


export default function Banner() {
    return (
      <div className="relative bg-white overflow-hidden" style={{ backgroundImage: 'url(/config/images/Background.png)', backgroundSize: 'cover' }}>
        <Header />
        <div className="pt-16 pb-80 sm:pt-24 sm:pb-40 lg:pt-30 lg:pb-48 h-screen">
          <div className="flex-col max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:static">
            <div className="sm:max-w-lg mt-auto">
                <StyledLogo alt={"logo"} src={"/config/images/logo_no_text.png"} />
              <h1 className="text-5xl font font-extrabold tracking-tight text-gray-800 sm:text-6xl">
                Ethereal Apes
                {/* <IonIcons name="camera" /> */}
              </h1>
              <p className="mt-4 text-xl text-black" style={{ mixBlendMode: 'normal' }}>
              Sold out!
              </p>
              <a href="https://opensea.io/collection/ethereal-apes-official" className="inline-block text-center bg-gray-700 border border-transparent shadow-md rounded-md py-3 px-12 mt-8  text-lg font-medium text-white hover:bg-gray-900">Opensea</a>
              {/* <NFT></NFT> */}
            </div>
          </div>
        </div>
      </div>
    )
  }
  